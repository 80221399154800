import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { SpacerComponent } from 'src/app/components/shared/spacer/spacer.component';

@Component({
  selector: 'app-create-edit-dialog',
  templateUrl: './create-edit-dialog.component.html',
  styleUrl: './create-edit-dialog.component.scss',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, SpacerComponent],
})
export class CreateEditDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  onNew() {
    this.router.navigate([this.data.newRoute]);
    this.dialogRef.close('new');
  }

  onEdit() {
    this.router.navigate([this.data.editRoute]);
    this.dialogRef.close('edit');
  }
}
