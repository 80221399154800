<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  {{ data.content }}
</div>

<mat-dialog-actions class="px-4">
  <button mat-stroked-button (click)="onEdit()" color="grey">Bearbeiten</button>

  <app-spacer />

  <button mat-raised-button (click)="onNew()" color="primary">
    Neu erstellen
  </button>
</mat-dialog-actions>
